import { auth } from "./auth";

export const API_ROUTE_PREFIX = "/swapi/";

export interface AppInsightsConfiguration {
  appInsightsRole: string;
  appInsightsConnectionString: string;
}

export async function apiGetAppInsightsConfiguration() {
  const token = await auth.acquireToken();
  const response = await fetch(API_ROUTE_PREFIX + "Configuration/AppInsights", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error(`AppInsights config: ${response.status} - ${response.statusText}`);
  }
  const config = await response.json();
  return config as AppInsightsConfiguration;
}
export const userActions = {
  saveUserLogging: async() => {
    const token = await auth.acquireToken();
    await fetch(API_ROUTE_PREFIX + "SciWorm/SaveUserLoggedAction", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
