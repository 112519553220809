import { apiGetAppInsightsConfiguration } from "./http";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export async function initializeWebAppInsights() {
  const insightsConfig = await apiGetAppInsightsConfiguration();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: insightsConfig.appInsightsConnectionString,
      enableAutoRouteTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
  return appInsights;
}
